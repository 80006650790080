
<template>
  <div class="gf-ys" v-if="!isHide">
    {{ hours | filterTimeData }}小时{{ min | filterTimeData }}分钟{{label}}
  </div>
</template>

<script>
export default {
  name: "backEndTime",
  props: {
    time: String,
    label:String,
  },
  mounted() {
    this.BackDate();
  },
  data() {
    return {
      hours: "",
      min: "",
      sec: "",
      timer: "",
      name:"",
      isHide:false,
    };
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    BackDate() {
      // 获取当前时间
      let now = new Date().getTime();
      let end = new Date(`${this.time}`.replace(/-/g, "/")).getTime();// 活动结束时间
      

      if (now >= end) {
        this.hours = 0;
        this.min = 0;
        this.sec = 0;
        this.isHide = true;
        return;
      }
      // 用结束时间戳减去当前时间
      let msec = end - now;
      this.hours = Math.floor((msec / (1000 * 60 * 60))); //算出小时数
      this.min = Math.floor((msec / (1000 * 60)) % 60); //算出分钟数
      this.sec = Math.floor((msec / 1000) % 60); //算出秒数

      //定义this指向
      const that = this;
      // 使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
      this.timer = setTimeout(function () {
        that.BackDate();
      }, 1000);
    },
  },
};
</script>

<style>
</style>