import { render, staticRenderFns } from "./backEndTime.vue?vue&type=template&id=63472e37&"
import script from "./backEndTime.vue?vue&type=script&lang=js&"
export * from "./backEndTime.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/k8s_dtk_vue_cms_server_pc_v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63472e37')) {
      api.createRecord('63472e37', component.options)
    } else {
      api.reload('63472e37', component.options)
    }
    module.hot.accept("./backEndTime.vue?vue&type=template&id=63472e37&", function () {
      api.rerender('63472e37', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BackDate/backEndTime.vue"
export default component.exports