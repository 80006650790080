<template>
  <div class="rec-block fav" v-if="items.length >= 3" bi-route="xihuan">
    <div class="tit">
      <span>可能喜欢</span>
      <ul class="pagination">
        <li class="prev btn" @click="handlePrev">
          <icon-font class="iconfont" type="icon-zuojiantou" />
        </li>
        <li
          class="pointer"
          v-for="(item, index) in listLength"
          :key="index"
          :class="current === index ? 'active' : ''"
          @click="tabChange(index)"
        ></li>
        <li class="next btn" @click="handleNext">
          <icon-font class="iconfont" type="icon-youjiantou" />
        </li>
      </ul>
    </div>
    <ul>
      <li
        class="rec-list"
        v-for="(ite, index) in tabList"
        @click="toDel(ite.id || ite.goodsId)"
        :key="index"
        bi-click="list"
        :data-index="index"
        :data-gid="ite.id || ite.goodsId"
        :data-pingtai="type"
      >
        <div class="fl img-cent">
          <img
            :src="ite.mainPic + '_310x310.jpg'"
            width="90"
            height="90"
            style="background: #ccc"
            alt=""
          />
        </div>
        <div class="content">
          <p>
            <i class="ico tmht" v-if="ite.haitao === 1"></i>
            <i class="ico tmcs" v-else-if="ite.tchaoshi === 1"></i>
            <i class="ico tm" v-else-if="ite.shopType === 1"></i>

            {{ ite.dtitle || ite.title }}
          </p>

          <section class="labels clearfix"></section>

          <span class="coupon">
            <i><b>¥</b>{{ ite.actualPrice }}</i>
            <b class="old-pri">￥{{ ite.originalPrice }}</b></span
          >
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import IconFont from "@/components/IconFont.vue";
export default {
  name: "maybelike",
  props: {
    items: Array,
    fun: Function,
    funLike: Function,
    type: String
  },
  components: {
    IconFont,
  },
  data() {
    return {
      current: 0,
      tabList: [],
      listLength: 0,
    };
  },
  mounted() {
    if(this.items.length){
      this.listLength = Math.ceil(this.items.length / 5);
      this.handleList(0);
    }
  },
  methods: {
    handleList(cuurent) {
      let list = [];
      for (let i = cuurent * 5; i < cuurent * 5 + 5; i++) {
        list.push(this.items[i]);
      }
      this.tabList = list.filter(Boolean);
    },
    handlePrev() {
      let a = this.current - 1;
      if (a < 0) {
        this.current = this.listLength - 1;
        this.handleList(this.current);
        return;
      }
      this.current--;
      this.handleList(this.current);
    },
    handleNext() {
      let a = this.current + 1;
      if (a >= this.listLength) {
        this.current = 0;
        this.handleList(this.current);
        return;
      }
      this.current++;
      this.handleList(this.current);
    },
    tabChange(index) {
      this.current = index;
      this.handleList(this.current);
    },
    toDel(id) {
      window.open(`?r=/l/d&id=${id}&from=dtk`, "_blank");
      // this.$routes.open({
      //   path: this.$route.path,
      //   query: {
      //     id: id,
      //     r: 'l/d'
      //   },
      // });
      this.fun();
      this.funLike();
      // window.scrollTo(0, 0);
    },
  },
  watch: {
    items(state) {
      this.listLength = Math.ceil(this.items.length / 5);
      this.handleList(0);
    },
  },
};
</script>

<style lang="less" scoped>
.rec-block {
  width: 300px;
  background: #fff;
  padding-bottom: 6px;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  max-height: 670px;
  overflow: hidden;
  margin-bottom: 20px;
  .tit {
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    font-size: 18px;
    height: 44px;
    line-height: 26px;
    box-sizing: border-box;
    margin: 5px 0;
    span {
      border-left: 3px solid #ff2e54;
      padding: 2px 0 0 10px;
    }
    .pagination {
      display: flex;
      .prev {
        margin: 0 5px;
        color: #888;
        cursor: pointer;
        .iconfont {
          &:hover {
            color: #ff2e54;
          }
        }
      }
      .next {
        margin: 0 5px;
        color: #888;
        cursor: pointer;
        .iconfont {
          &:hover {
            color: #ff2e54;
          }
        }
      }
      .pointer {
        width: 5px;
        height: 5px;
        background: #d9d9d9;
        margin: 10px 5px;
      }
      .active {
        background: #ff2e54;
      }
    }
  }
  .rec-list {
    display: flex;
    padding: 16px 0;
    margin: 0 20px;
    cursor: pointer;
    &:hover .img-cent img {
      opacity: 0.8;
    }
    &:hover .content p {
      color: #ff2e54;
    }
    .img-cent {
      position: relative;
      width: 90px;
      height: 90px;
      text-align: center;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    .content {
      display: inline-block;
      width: 158px;
      margin-left: 10px;
      height: 90px;
      p {
        font-size: 12px;
        line-height: 17px;
        color: #777;
        font-family: microsoft yahei;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .tm {
          width: 15px;
          height: 15px;
          background: url("../../assets/search/prod_icon1.png") center/contain
            no-repeat;
          float: left;
          position: relative;
          top: 1px;
          margin-right: 5px;
        }
        .tmcs {
          width: 15px;
          height: 15px;
          background: url("../../assets/search/prod_icon3.png") center/contain
            no-repeat;
          float: left;
          position: relative;
          top: 1px;
          margin-right: 5px;
        }
        .tmht {
          width: 15px;
          height: 15px;
          background: url("../../assets/search/prod_icon2.png") center/contain
            no-repeat;
          float: left;
          position: relative;
          top: 1px;
          margin-right: 5px;
        }
      }
      .labels {
        height: 40px;
        overflow: hidden;
        margin: 8px 0 0;
      }
      .coupon {
        font-size: 12px;
        color: #999;
        display: block;
        i {
          font-size: 21px;
          font-style: initial;
          font-family: Arial;
          color: #fc4d52;
          line-height: 21px;
          position: relative;
          top: 2px;
          margin-left: 5px;
          b {
            font-size: 14px;
            font-weight: 400;
            margin-right: 2px;
          }
        }
        .old-pri {
          display: inline-block;
          color: #ababab;
          font-size: 12px;
          font-weight: 400;
          line-height: 0;
          border-top: 1px solid #ababab;
          position: relative;
          top: 2px;
          margin-left: 2px;
          font-family: Arial;
        }
      }
    }
    &:hover {
      color: #333;
    }
  }
}
</style>