var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isHide
    ? _c("div", { staticClass: "gf-ys" }, [
        _vm._v(
          " " +
            _vm._s(_vm._f("filterTimeData")(_vm.hours)) +
            "小时" +
            _vm._s(_vm._f("filterTimeData")(_vm.min)) +
            "分钟" +
            _vm._s(_vm.label) +
            " "
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }