var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "topList_container" }, [
        _c("div", { staticClass: "mask" }),
        _c("div", { staticClass: "content_wrap" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.title) + "·热销榜")
          ]),
          _c("div", { staticClass: "content" }, [
            _c("span", [_vm._v("数据来源今日真实销量")]),
            _c(
              "div",
              { staticClass: "top_list" },
              _vm._l(_vm.items, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "list_item",
                    on: {
                      click: function($event) {
                        return _vm.toDel(item.id || item.goodsId)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "list_item_left" }, [
                      _c("img", { attrs: { src: item.mainPic, alt: "" } }),
                      _c("i", [
                        _vm._v(
                          _vm._s(index + 1 < 10 ? "0" + (index + 1) : index + 1)
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "list_item_right" }, [
                      _c("div", { staticClass: "list_item_right_title" }, [
                        item.tchaoshi
                          ? _c("img", {
                              attrs: {
                                src: require("../../assets/search/prod_icon3.png"),
                                alt: ""
                              }
                            })
                          : item.shopType
                          ? _c("img", {
                              attrs: {
                                src: require("../../assets/search/prod_icon1.png"),
                                alt: ""
                              }
                            })
                          : _vm._e(),
                        _c("span", [_vm._v(_vm._s(item.dtitle || item.title))])
                      ]),
                      _c("div", { staticClass: "list_item_right_mid" }, [
                        _vm._v(
                          " 今日热销" +
                            _vm._s(_vm._f("salesNum")(item.dailySales)) +
                            "件 "
                        )
                      ]),
                      _c("div", { staticClass: "list_item_right_bottom" }, [
                        _c("div", { staticClass: "price" }, [
                          _c("span", [
                            _vm._v("￥"),
                            _c("b", [_vm._v(_vm._s(item.actualPrice))])
                          ]),
                          _c("i", [_vm._v("￥" + _vm._s(item.originalPrice))])
                        ]),
                        item.couponPrice
                          ? _c("div", { staticClass: "quan" }, [
                              _vm._v(" " + _vm._s(item.couponPrice) + "元券 ")
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ]),
          _c("div", { staticClass: "close_btn" }, [
            _c("img", {
              attrs: { src: require("../../assets/detail/close.png"), alt: "" },
              on: { click: _vm.handleClose }
            })
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }