<template>
  <div class="topList_container" v-if="visible">
    <div class="mask"></div>
    <div class="content_wrap">
      <div class="title">{{ title }}·热销榜</div>
      <div class="content">
        <span>数据来源今日真实销量</span>
        <div class="top_list">
          <div
            class="list_item"
            @click="toDel(item.id || item.goodsId)"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="list_item_left">
              <img :src="item.mainPic" alt="" />
              <i>{{ index + 1 < 10 ? `0${index + 1}` : index + 1 }}</i>
            </div>
            <div class="list_item_right">
              <div class="list_item_right_title">
                <img
                  src="../../assets/search/prod_icon3.png"
                  v-if="item.tchaoshi"
                  alt=""
                />
                <img
                  src="../../assets/search/prod_icon1.png"
                  v-else-if="item.shopType"
                  alt=""
                />
                <span>{{ item.dtitle || item.title }}</span>
              </div>
              <div class="list_item_right_mid">
                今日热销{{ item.dailySales | salesNum }}件
              </div>
              <div class="list_item_right_bottom">
                <div class="price">
                  <span
                    >￥<b>{{ item.actualPrice }}</b></span
                  >
                  <i>￥{{ item.originalPrice }}</i>
                </div>
                <div class="quan" v-if="item.couponPrice">
                  {{ item.couponPrice }}元券
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="close_btn">
        <img @click="handleClose" src="../../assets/detail/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topList",
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    items: Array,
    title: String,
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  methods: {
    handleClose() {
      document.body.style.overflow = "visible";
      this.$emit("onClose");
    },
    toDel(id) {
      window.open(`?r=/l/d&id=${id}&from=dtk`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.topList_container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  .mask {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .content_wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: auto;
    text-align: center;
    padding-top: 120px;
    .title {
      width: 262px;
      height: 87px;
      line-height: 127px;
      background: url("../../assets/detail/top_title.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      font-weight: Bold;
      font-size: 27px;
      color: #ff403d;
    }
    .content {
      margin: 0 auto;
      width: 400px;
      height: 634px;
      background: #ffffff;
      border-radius: 5px;
      span {
        font-size: 12px;
        color: #525252;
      }
      .top_list {
        overflow-y: scroll;
        width: 100%;
        height: 95%;
        padding: 30px 25px;
        box-sizing: border-box;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          width: 8px !important;
          height: 8px !important;
        }

        &::-webkit-scrollbar-button:vertical {
          display: none !important;
          border-radius: 4px !important;
        }

        &::-webkit-scrollbar-corner,
        ::-webkit-scrollbar-track {
          background-color: rgba(255, 255, 255, 0.5) !important;
          border-radius: 4px !important;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #dddfe0 !important;
          border-radius: 4px !important;
        }

        &::-webkit-scrollbar-thumb:vertical:hover {
          background-color: #d2d3d4;
        }

        &::-webkit-scrollbar-thumb:vertical:active {
          background-color: #c0c1c2 !important;
        }
        .list_item {
          display: flex;
          margin-bottom: 20px;
          cursor: pointer;
          &:hover .list_item_left img {
            opacity: 0.8;
          }
          &:hover .list_item_right .list_item_right_title span {
            color: #ff2e54;
          }
          .list_item_left {
            position: relative;
            margin-right: 8px;
            img {
              width: 110px;
            }
            i {
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              background: url("../../assets/detail/top.png") no-repeat;
              background-size: 100% 100%;
              width: 25px;
              height: 29px;
              line-height: 29px;
              font-style: inherit;
              font-size: 14px;
              color: #ffffff;
              font-weight: bold;
            }
          }
          .list_item_right {
            .list_item_right_title {
              display: flex;
              align-items: center;
              margin-bottom: 11px;
              img {
                width: 14px;
                margin-right: 10px;
              }
              span {
                display: inline-block;
                width: 204px;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
                color: #333333;
              }
            }
            .list_item_right_mid {
              width: 188px;
              height: 31px;
              line-height: 28px;
              background: url("../../assets/detail/top_list_rx_bg.png")
                no-repeat;
              background-size: 100% 100%;
              font-size: 14px;
              color: #ffffff;
              padding-left: 20px;
              box-sizing: border-box;
              margin-bottom: 22px;
            }
            .list_item_right_bottom {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .price {
                span {
                  color: #fe2e2e;
                  font-size: 18px;
                  b {
                    font-size: 26px;
                    color: #fe2e2e;
                    font-weight: inherit;
                  }
                }
                i {
                  font-style: inherit;
                  text-decoration: line-through;
                  font-size: 14px;
                  color: #ababab;
                }
              }
              .quan {
                background: url("../../assets/detail/quan.png") no-repeat;
                background-size: 100% 100%;
                width: 65px;
                height: 22px;
                line-height: 22px;
                font-size: 14px;
                color: #ff0000;
              }
            }
          }
        }
      }
    }
    .close_btn {
      margin-top: 20px;
      img {
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>