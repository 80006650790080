<template>
  <div class="taobao_container" v-if="isLoad">
    <Loading :visible="loading" />
    <!-- <div class="nav-wrap">
      <div class="text">
        <a href="">首页</a> <i></i> <a href="">领券直播</a> <i></i>
        <a href="">咚咚抢</a> <i></i> <a href="">超级人气榜</a> <i></i>
        <a href="">9块9包邮</a> <i></i> <a href="">折上折</a> <i></i>
        <span>优惠详情</span>
      </div>
    </div> -->
    <div class="detail-wrapper">
      <div class="left-container" bi-route="left">
        <div class="detail-row">
          <div style="display: flex">
            <div class="img-block">
              <span class="img">
                <img class="lg-img" :src="imgLargeUrl + '_640x640.jpg'" alt="" />
                <ul class="sm-img" v-if="images.length > 0">
                  <li v-for="(item, index) in imagess" :key="index" @mouseenter="mousemove(item)">
                    <a :href="openUrl" target="_blank">
                      <img :src="item + '_310x310.jpg'" :class="imgLargeUrl === item ? 'img_hover' : ''" alt="" />
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div class="detail-col">
              <!-- <span class="title" @click="handleBuy">
                <span class="tmht" v-if="goodsData.haitao === 1"></span>
                <span class="tmcs" v-else-if="goodsData.tchaoshi === 1"></span>
                <span class="tm" v-else-if="goodsData.shopType === 1"></span>
                <span class="title">{{ goodsData.dtitle }}</span>
              </span> -->

              <a :href="openUrl" target="_blank">
                <span class="title">
                  <span class="tmht" v-if="goodsData.haitao === 1"></span>
                  <span class="tmcs" v-else-if="goodsData.tchaoshi === 1"></span>
                  <span class="tm" v-else-if="goodsData.shopType === 1"></span>
                  <span class="title">{{ goodsData.dtitle || goodsData.title }}</span>
                </span>
              </a>

              <div class="active-banners" v-if="marketConfig">
                <div class="act-bg" :style="`background-image:url(${marketConfig.pc_detail_banner})`">
                  <div class="act-text">
                    <span class="gf-ys gf-center" v-if="marketConfig.goods_type == 2 &&
                      goodsData.previewStartTime
                      "><back-end-time :time="goodsData.previewStartTime" label="后可付定金" /></span>
                    <span class="gf-ys gf-center" v-if="marketConfig.pc_detail_text &&
                      marketConfig.goods_type == 1
                      ">{{ marketConfig.pc_detail_text }}</span>

                    <span class="gf-ys" v-if="marketConfig.goods_type == 2 &&
                      goodsData.quanMLink &&
                      goodsData.quanMLink != '0'
                      ">定金{{ goodsData.quanMLink }}元抵{{
    AccAdd({
      num: [goodsData.quanMLink, goodsData.hzQuanOver || 0],
    })
  }}元</span>

                    <span class="gf-ys" v-if="marketConfig.goods_type == 1">
                      <back-date :ismarket="true" :start="marketConfig.ac_start_time" :end="marketConfig.ac_end_time"
                        text=" " />
                    </span>

                    <!-- <div class="rank-list">
                      <span>
                        <i class="hot-ico"></i>
                        螺蛳粉 | <span>热销榜第一名</span>
                      </span>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="jhs-banners" v-else-if="goodsData.activityType === 3 && jhsEndTime > nowTime">
                <div class="act-bg">
                  <div class="act-text">
                    <back-date :start="goodsData.activityStartTime" :end="goodsData.activityEndTime" />
                  </div>
                </div>
              </div>
              <div class="time-num">
                <span class="time" v-if="goodsData.createTime">上新时间：{{ getTime }}</span>
                <span class="num">{{
                  marketConfig && marketConfig.goods_type == 2
                  ? "累计预定"
                  : "累计销量"
                }}：<span>{{ goodsData.monthSales | salesNum(_, 1) }}</span>
                  件</span>
              </div>
              <div class="coupon" v-if="goodsData.couponPrice && goodsData.couponPrice > 0">
                独家优惠：<span class="quan">{{ goodsData.couponPrice }}元券</span>
              </div>
              <!-- 细分类目排行 -->
              <div class="rank-list" v-if="goodsData.subdivisionRank > 0 && goodsData.couponPrice > 0
                " @click="showTopList = true">
                <span>
                  <i class="hot-ico"></i>
                  {{ goodsData.subdivisionName }} |
                  <span>热销排行第{{ goodsData.subdivisionRank }}名>></span>
                </span>
              </div>
              <div class="goods-label" :style="{ borderTop: dom ? '1px solid #ececec' : 'none' }">
                <span>商品标签：</span>
                <!-- 特色文案 -->
                <span class="label specil" v-if="goodsData.specialText && goodsData.specialText[0]"><i
                    class="specil-icon"></i><span class="specil_text">{{
                      goodsData.specialText[0]
                    }}</span>
                </span>
                <span class="label" v-if="marketConfig && marketConfig.remark">{{ marketConfig.remark }}</span>
                <span class="label" v-if="goodsData.shopType">天猫</span>
                <span class="label" v-if="goodsData.activityType === 3">聚划算</span>
                <span class="label" v-if="goodsData.freeshipRemoteDistrict">包邮</span>

                <span class="label" v-if="goodsData.discountFull">每满{{ goodsData.discountFull }}减{{ goodsData.discountCut
                }}
                </span>
              </div>
              <div class="price_con" :style="{
                  borderTop: dom ? 'none' : '1px solid #ececec',
                  margin: dom ? 0 : '15px 0 5px',
                  padding: dom ? 0 : '15px 0 0',
                }">
                <div class="price">
                  ￥ <i>{{ goodsData.actualPrice }}</i>
                  <b v-if="goodsData.couponPrice">
                    ￥<i>{{ goodsData.originalPrice }}</i>
                  </b>
                </div>
                <div class="lowest" v-if="goodsData.lowest">
                  <img src="../../assets/detail/lowest.png" alt="" />
                  <span>30天最低价</span>
                </div>
              </div>

              <div class="buy-share">
                <div class="share-btn">
                  <!-- <span
                    @click="handleBuy"
                    class="buy"
                    v-if="marketConfig && marketConfig.goods_type == 2"
                    style="background:#a70fd1;"
                    >领券预定</span
                  > -->
                  <a bi-route="lingquan" :href="openUrl" class="buy" v-if="marketConfig && marketConfig.goods_type == 2"
                    style="background: #a70fd1" target="_blank">领券预定</a>

                  <!-- <span @click="handleBuy">领券购买</span> -->
                  <a :href="openUrl" class="buy" v-else target="_blank">领券购买</a>
                  <span bi-click="saoma" class="buy share_qrcode" @mouseenter="handleCode">
                    <i></i>
                    扫码购买
                    <div class="qrcode">
                      <img src="../../assets/detail/loading.png" class="loading" v-if="isShow" alt="" />
                      <span id="qrcode"></span>
                      <span>手机淘宝扫码</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="goods-desc cf">
            <div class="tit">推荐理由</div>
            <div class="rec-text">
              <span>{{ goodsData.desc }}</span>
            </div>
            <div class="content" v-if="goodsData.couponPrice">
              <p>
                此款商品正在进行限时活动，原来天猫售价{{
                  goodsData.originalPrice
                }}元，现有{{ goodsData.couponPrice }}元优惠券，到手仅需{{
  goodsData.actualPrice
}}元，绝对超值，有需要可速度下单哦！
              </p>
            </div>
            <div class="feedback">
              我要举报：
              <span v-for="(item, index) in reports" :key="index" @click="toFeedback(item.err_type)">{{ item.content
              }}</span>
            </div>
          </div>
        </div>
        <div class="shop-msg">
          <div class="tit"><span>店铺信息</span></div>
          <div class="shop-block">
            <div>
              <img class="shop-img fl" :src="goodsData.shopLogo
                ? goodsData.shopLogo + '_310x310.jpg'
                : require('../../assets/detail/default.png')
                " alt="" width="60" height="60" style="background: rgb(245, 245, 245); display: block" />
              <div class="shop-name fl">
                <p>{{ goodsData.shopName }}</p>
                <p class="iconfont icon-detail_tmall" v-if="goodsData.shopType"></p>
                <icon-font class="iconfont icon-detail_tb" type="icon-detail_taobao" v-else />
              </div>
            </div>
            <ul class="shop-score fr">
              <li>
                <p>宝贝描述</p>
                <span>{{ goodsData.dsrScore }}
                  <!-- <i class="ico high">高</i> -->
                </span>
              </li>
              <li>
                <p>卖家服务</p>
                <span>{{ goodsData.serviceScore }}
                  <!-- <i class="ico high">高</i> -->
                </span>
              </li>
              <li>
                <p>物流服务</p>
                <span>{{ goodsData.shipScore }}
                  <!-- <i class="ico high">高</i> -->
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="goods-detail" v-if="detailPics && detailPics.length > 0">
          <div class="tit"><span>商品详情</span></div>
          <img :src="item.img + '_640x640.jpg'" alt="" v-for="(item, index) in detailPics" :key="index"
            v-lazy="item.img + '_640x640.jpg'" />
        </div>
      </div>
      <div class="right-container">
        <maybe-like :items="likeItems" :fun="getDetailData" :funLike="getLikeData" type="taobao" />
        <hot-sales :items="todayItmes" :fun="getDetailData" :funLike="getLikeData" type="taobao" />
      </div>
    </div>
    <statement :items="id" />
    <TopList v-if="showTopList" :items="hotSalesDataList" :title="goodsData.subdivisionName" :visible="showTopList"
      @onClose="showTopList = false" />
  </div>
</template>

<script>
import MaybeLike from "@/components/MaybeLike";
import BackDate from "@/components/BackDate";
import Loading from "@/components/loading";
import BackEndTime from "@/components/BackDate/backEndTime";
import HotSales from "@/components/HotSales";
import Statement from "@/components/Statement";
import IconFont from "@/components/IconFont.vue";
import { Format } from "@/utils/timeFormat";
import { mapGetters } from "vuex";
import { importCDN, AccAdd, asyncGetData } from "@/utils/utils";
import TopList from "@/components/TopList";
export default {
  components: {
    MaybeLike,
    IconFont,
    BackDate,
    Statement,
    Loading,
    HotSales,
    BackEndTime,
    TopList,
  },
  computed: {
    getTime() {
      return Format(new Date(this.goodsData.createTime), "MM-dd hh:mm");
    },
    ...mapGetters(["marketGroup", "config", "marketGroupConfig"]),
  },
  data() {
    return {
      marketConfig: false,
      showTopList: false,
      imgLargeUrl: "",
      nowTime: "",
      loading: true,
      ShortLink: "",
      Link: "",
      goodsData: {},
      couponId: "", //券id
      id: 0,
      originUrl: "",
      goodsId: "",
      newTime: "",
      images: [],
      likeItems: [],
      todayItmes: [],
      detailPics: [],
      jhsEndTime: 0,
      isShow: true,
      imgs: [],
      hours: 0,
      min: 0,
      sec: 0,
      now: "",
      end: "",
      hotSalesDataList: [],
      reports: [
        { err_type: 1, content: "价格错误" },
        { err_type: 2, content: "优惠券失效" },
        { err_type: 3, content: "其他" },
      ],
      next: true,
      nextTwo: true,
      openUrl: "",
      dom: "",
      isLoad: false,
    };
  },
  mounted() {
    if (!this.$route.query.id) {
      document.body.style.opacity = "1";
      this.$router.replace("/index/notfound");
    }
    this.nowTime = new Date().getTime();
    this.originUrl = window.location.origin;
    importCDN("https://public.ffquan.cn/lib/qrcode.js");
    this.getDetailData();
    this.getLikeData();

    this.getTodayData();
  },
  updated() {
    this.$nextTick(() => {
      if (JSON.stringify(this.goodsData) != "{}") {
        this.dom = document.querySelector(".goods-label");
        if (this.dom && this.dom.children.length < 2) {
          this.dom.parentNode.removeChild(this.dom);
        }
      }
    });
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    AccAdd: AccAdd,
    // 读取当前商品活动配置
    getMarketConfig() {
      const market = (this.goodsData.marketGroup || [])
        .map((res) => this.marketGroupConfig[res])
        .filter((res) => res)[0];

      if (!this.marketConfig) {
        this.marketConfig = market;
      }
      return "";
    },
    handleJumpPage() {
      // 判断是否配置显示详情页
      if (this.config.detail_switch == 1) {
        document.body.style.opacity = "1";
        this.isLoad = true;
        this.setConfig();
      } else {
        this.isLoad = false;
        this.transformLink((res) => {
          if (res && res.code === 1) {
            window.location.href = res.data.ShortLink || res.data.Link;
          } else {
            this.transformFailed(this.goodsData.goodsId, true)
            // this.$router.push("/index/notfound");
            // document.body.style.opacity = "1";
          }
        });
      }
    },
    // 去反馈
    toFeedback(type) {
      this.$router.push({
        path: `/index/feedbackpc`,
        query: {
          id: this.$route.query.id,
          type: type,
        },
      });
    },
    async handleBuy(e) {
      if (!this.nextTwo) {
        return;
      }
      this.nextTwo = false;
      if (this.ShortLink || this.Link) {
        this.handleBiEvent(e);
        this.timer = setTimeout(() => {
          this.nextTwo = true;
          window.open(this.ShortLink || this.Link);
        }, 500);
        return;
      }
      const res = await this.transformLink(function () { }, e);
      if (res && res.code === 1) {
        this.nextTwo = true;

        // window.location.href = res.data.ShortLink || res.data.Link;
      }
    },
    handleCode(e) {
      const checkQRCode = function () {
        return window.QRCode && (this.ShortLink || this.Link);
      }
      asyncGetData(checkQRCode.bind(this), 3000).then(() => {
        this.handleBiEvent(e);
        let str = `qrcode`;
        let dom = document.getElementById(str);
        if (!dom) return;
        if (!dom.children.length) {
          new QRCode(dom, {
            text: this.ShortLink,
            height: 100,
            width: 100,
          });
        }
        return;
      });
    },
    mousemove(url) {
      this.imgLargeUrl = url;
    },
    getLikeData() {
      let id = this.$route.query.id;
      this.$services.getLikeData({ id, size: 20 }).then((res) => {
        if (res && res.code === 0) {
          this.likeItems = res.data;
        }
      });
    },

    handleBiEvent(e) {
      let timestamp = new Date().getTime();
      const id = this.id;
      const goodsId = this.goodsId || this.$route.query.id;
      const data = {
        site_id: this.config.taobao.uid,
        goodsid: `${goodsId}`,
        gid: id,
        pid: this.config.taobao.pid,
        need_tpwd: 1,
        auth_id: this.config.taobao.auth_id, //授权id
        not_use_commission_plan: 1, //1不使用高佣计划
        need_short_link: 1,
        is_auto_quan: 1,
        need_item_link: 0,
        time: timestamp,
        d_title: "复制口令购买",
      };
      if (this.couponId) {
        data.quan_id = this.couponId;
      }
      if (e) {
        try {
          this.$BIJsdk.onEvent({
            event: e.target,
            extra: {
              // p: _data,
              ds: "cms-pc",
              referer: window.encodeURIComponent(location.href),
              pingtai: "taobao",
              ...data,
            },
            event_type: e.type,
          });
        } catch (error) { }
      }
    },
    // 转链失败
    transformFailed(goodsId, isJump) {
      this.openUrl = `https://uland.taobao.com/item/edetail?id=${goodsId}`
      let currentDate = new Date();
      let targetDate = new Date("2023/09/28");
      if (currentDate < targetDate && this.config.taobao.pid) {
        this.openUrl = `https://uland.taobao.com/coupon/edetail?activityId=${this.couponId}&pid=${this.config.taobao.pid}&itemId=${goodsId}&src=qhkj_dtkp&dx=1`;
      }

      //将链接转换成短链防止二维码内容过多
      this.$services.createShort({ url: this.openUrl }).then(res => {
        if (res.code == 1) {
          this.ShortLink = res.data;
        } else {
          this.ShortLink = this.openUrl;
        }
        if (isJump) window.location.href = this.ShortLink;
      })
    },
    // 转链
    transformLink(callback, e) {
      if (!this.next) {
        return;
      }
      this.next = false;
      if (this.ShortLink || this.Link) {
        window.open(this.ShortLink || this.Link);
        return;
      }
      let timestamp = new Date().getTime();
      const id = this.id;
      const goodsId = this.goodsId || this.$route.query.id;
      const urlQuery = { ...this.$route.query };
      const data = {
        site_id: this.config.taobao.uid,
        goodsid: urlQuery.origin_id ? urlQuery.origin_id : `${goodsId}`,
        gid: id,
        pid: this.config.taobao.pid,
        need_tpwd: 1,
        auth_id: this.config.taobao.auth_id, //授权id
        not_use_commission_plan: 1, //1不使用高佣计划
        need_short_link: 1,
        is_auto_quan: 1,
        need_item_link: 0,
        time: timestamp,
        biz_scene_id: this.goodsInfo.bizSceneId || 1, // 场景id 1 动态id 2 消费比价场景 3商城导购场景
        d_title: "复制口令购买",
      };
      if (this.couponId) {
        data.quan_id = this.couponId;
      }
      let newHref = '';
      if (urlQuery.origin_id) {
        newHref = this.urlDelParams('origin_id').replace(urlQuery.id, urlQuery.origin_id)
        console.log(newHref);
      }
      return importCDN("https://public.ffquan.cn/lib/base64.min.js").then(
        (res) => {
          const _data = window.Base64.encodeURI(JSON.stringify(data));
          this.handleBiEvent(e);
          return this.$services
            .transformLink({
              p: _data,
              ds: "cms-pc",
              referer: window.encodeURIComponent(newHref ? newHref : location.href),
              new_refer: (function(){
                var url = window.location.href;
                return url + (url.indexOf("?")>-1 ? "&" : "?") + 'event_id=cms&path='+encodeURIComponent('/detail/left/ljgm')+'&referer='+encodeURIComponent(document.referrer);
              })()

            })
            .then((res) => {
              if (res && res.code === 1) {
                this.ShortLink = res.data.ShortLink;
                this.Link = res.data.Link;
                this.openUrl = res.data.ShortLink || res.data.Link;
              } else {
                // 转链失败
                this.transformFailed(goodsId);
              }
              this.isShow = false;
              callback && callback(res);
              this.next = true;
              return res;
            })
            .catch(() => {
              // 转链失败
              this.transformFailed(goodsId);
            });
        }
      );
    },
    //删除路由参数
    urlDelParams(name) {
      var loca = window.location
      var baseUrl = loca.origin + loca.pathname + '?'
      var query = loca.search.substr(1)
      if (query.indexOf(name) > -1) {
        var obj = {}
        var arr = query.split('&')
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].split('=')
          obj[arr[i][0]] = arr[i][1]
        }
        delete obj[name]
        var url =
          baseUrl +
          JSON.stringify(obj)
            .replace(/[\"\{\}]/g, '')
            .replace(/\:/g, '=')
            .replace(/\,/g, '&')
        return url
      }
    },
    getTodayData() {
      this.$services.getTodayData({ rankType: 1 }).then((res) => {
        if (res && res.code === 0) {
          this.todayItmes = res.data;
        }
      });
    },
    // sendReports(errType, content) {
    //   let params = {
    //     gid: this.$route.query.id,
    //     err_type: errType,
    //     content,
    //   };
    //   this.$services.sendReports({ ...params }).then((res) => {
    //     if (res && res.code === 1) {
    //       this.$modal.Toast(`感谢您的反馈！<br />我们会尽快进行处理`, 1500);
    //     }
    //   });
    // },
    getDetailData() {
      this.goodsId = this.$route.query.id;
      const id = this.$route.query.id;
      this.$services
        .getTbDetails({ ...(id.length === 8 ? { id: id } : { goodsId: id }) })
        .then((res) => {
          if (res && res.code === 0) {
            this.goodsData = res.data;
            this.images = [
              ...[res.data.mainPic],
              ...res.data.imgs.split(","),
            ].slice(0, 5);
            this.imagess = new Set(this.images);
            this.imgLargeUrl = res.data.mainPic;
            this.detailPics = res.data.detailPics
              ? JSON.parse(res.data.detailPics)
              : {};
            let jhsEnd = new Date(
              `${res.data.activityEndTime}`.replace(/-/g, "/")
            );
            this.jhsEndTime = jhsEnd.getTime();
            this.id = res.data.id;
            this.goodsId =
              res.data.goodsSign != "" ? res.data.goodsSign : res.data.goodsId;
            this.couponId = res.data.couponId; //券id
            document.title = `${res.data.title}_${this.config.site_name}`;
            this.goodsInfo = res.data;
            this.getMarketConfig();
            if (this.config.site_id) {
              this.handleJumpPage();
            }
          } else {
            document.body.style.opacity = "1";
            this.$router.replace("/index/notfound");
          }
          this.loading = false;
        });
    },
    setConfig() {
      var _this = this;
      if (Object.keys(this.config).length > 0) {
        _this.handleBuy();
      }
    },
    getHotSalesData() { },
  },
  watch: {
    isShow(state) {
      if (!state) {
        this.handleCode()
      }
    },
    marketGroupConfig(state) {
      this.getMarketConfig();
    },
    config() {
      document.title = `${this.goodsData.title}_${this.config.site_name}`;

      this.handleJumpPage();
    },
    goodsData(state) {
      this.$services
        .getHotSalesData({ subdivisionId: state.subdivisionId })
        .then((res) => {
          if (res && res.code === 0) {
            this.hotSalesDataList = res.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.taobao_container {
  width: 1200px - 2 - 23 * 2;
  margin: 0 auto;
  // border: 1px solid #f7e7e7;
  padding: 23px;

  .nav-wrap {
    margin-bottom: 20px;
    display: block;

    .text {
      color: #888;
      font-size: 12px;
      position: relative;

      i {
        color: #bcbcbc;
        display: inline-block;
        position: relative;
        top: 1px;
        border-left: 6px solid #bcbcbc;
        border-top: 6px solid #f6f6f6;
        border-bottom: 6px solid #f6f6f6;
        margin: 0 8px;

        &::before {
          content: "";
          position: absolute;
          left: -6px;
          top: -5px;
          border-left: 5px solid #f6f6f6;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
        }
      }

      a {
        padding: 0 5px;
        color: #444;

        &:hover,
        &:focus {
          color: #ff0000;
        }
      }
    }
  }

  .detail-wrapper {
    display: flex;
    width: 1200px;

    .left-container {
      width: 74.5%;

      .detail-row {
        background: #fff;
        padding: 20px 20px 40px;
        border: 1px solid #f1f1f1;
        margin-bottom: 20px;

        .img-block {
          float: left;

          .img {
            cursor: pointer;

            .sm-img {
              display: inline-block;
              margin-top: 2px;

              li {
                width: 45px;
                height: 45px;
                margin-left: 10px;
                cursor: auto;
                margin-top: 0;
                margin-bottom: 8px;
                cursor: pointer;

                img {
                  width: 45px;
                  height: 45px;

                  &:hover {
                    box-shadow: 0 0 6px 0 #ffc4cf;
                    border: 1px solid #ff2d54;
                  }
                }

                .img_hover {
                  box-shadow: 0 0 6px 0 #ffc4cf;
                  border: 1px solid #ff2d54;
                }
              }
            }

            .lg-img {
              width: 260px;
              height: 260px;
              float: left;
            }
          }
        }

        .detail-col {
          width: 58%;
          margin: -5px 0 0 38px;
          padding-bottom: 0;

          span.title {
            margin-left: 4px;
            display: flex;
            cursor: pointer;

            // .tmall {
            //   background: url("/images/tmall.png") center/contain no-repeat;;
            //   width: 16px;
            //   height: 16px;
            //   display: block;
            //   background-size: contain;
            //   margin-top: 4px;
            // }
            .tm {
              background: url("../../assets/detail/tm.png") center/contain no-repeat;
              width: 23px;
              height: 19px;
              display: block;
              background-size: contain;
              margin-top: 4px;
            }

            .tmcs {
              background: url("../../assets/search/prod_icon3.png") center/contain no-repeat;
              width: 23px;
              height: 19px;
              display: block;
              background-size: contain;
              margin-top: 4px;
            }

            .tmht {
              background: url("../../assets/search/prod_icon2.png") center/contain no-repeat;
              width: 23px;
              height: 19px;
              display: block;
              background-size: contain;
              margin-top: 4px;
            }

            span.title {
              float: left;
              width: 650px;
              min-height: 30px;
              overflow: hidden;
              display: block;
              font-size: 19px;
              color: #333;
              margin-left: 5px;

              &:hover {
                color: #666;
              }
            }
          }

          .active-banners {
            width: 100%;
            height: 38px;
            position: relative;

            .act-bg {
              background: url("../../assets/detail/act-bg.png") 100% 100%;
              position: absolute;
              left: -15px;
              bottom: 0;
              width: 542px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background-size: cover;

              .gf-center {
                position: absolute;
                left: 50%;
                top: 8px;
                text-align: center;
                line-height: 30px;
                width: 200px;
                margin-left: -100px;
              }

              .act-text {
                display: flex;
                align-items: center;
                float: right;
                line-height: 30px;
                color: #fff;
                font-size: 13px;
                margin: 8px 30px 0 0;

                .rank-list {
                  width: 134px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  background: rgba(255, 255, 255, 0.87);
                  border-radius: 10px;
                  margin-left: 10px;
                  color: red;
                  line-height: 20px;
                  padding: 0 8px;
                  box-sizing: border-box;
                }

                .hot-ico {
                  background: url("../../assets/detail/hot.png") center no-repeat;
                  background-size: 100% 100%;
                  width: 11px;
                  height: 13px;
                  display: inline-block;
                  margin-right: 2px;
                  margin-bottom: -2px;
                }
              }
            }
          }

          .jhs-banners {
            width: 100%;
            height: 38px;
            position: relative;

            .act-bg {
              background: url("../../assets/detail/jhs-bg.png") 100% 100%;
              position: absolute;
              left: -15px;
              bottom: 0;
              width: 542px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background-size: cover;

              .act-text {
                display: flex;
                align-items: center;
                float: right;
                line-height: 30px;
                color: #fff;
                font-size: 13px;
                margin: 8px 30px 0 0;

                .rank-list {
                  width: 134px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  background: rgba(255, 255, 255, 0.87);
                  border-radius: 10px;
                  margin-left: 10px;
                  color: red;
                  line-height: 20px;
                  padding: 0 8px;
                  box-sizing: border-box;
                }

                .hot-ico {
                  background: url("../../assets/detail/hot.png") center no-repeat;
                  background-size: 100% 100%;
                  width: 11px;
                  height: 13px;
                  display: inline-block;
                  margin-right: 2px;
                  margin-bottom: -2px;
                }
              }
            }
          }

          .coupon {
            .quan {
              line-height: 22px;
              height: 22px;
              background: url("../../assets/detail/quan.png") center #fff8f8;
              background-size: 100% 100%;
              font-size: 14px;
              border-radius: 2px;
              font-weight: 400;
              position: relative;
              min-width: 57px;
              text-align: center;
              box-sizing: border-box;
              color: #ff2b22;
              display: inline-block;
            }
          }

          .time-num {
            margin: 20px 0 10px;
            font-size: 12px;

            .time {
              margin-right: 105px;
            }

            .num {
              span {
                color: #ff2e54;
              }
            }
          }

          .goods-label {
            padding: 15px 0 0;
            margin: 15px 0 10px;
            font-size: 12px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .label {
              background: #f5f5f5;
              border-radius: 12px;
              border: 1px solid #ddd;
              padding: 3px 8px;
              margin: 0 10px 0 0;
              // display: inline-block;
            }

            .specil {
              background: #fff3f6;
              border: 1px solid #fe313e;
              text-align: center;
              color: #fe313e;
              display: flex;
              align-items: center;

              .specil-icon {
                width: 14px;
                height: 14px;
                background: url("../../assets/detail/car.png") no-repeat;
                background-size: 100% 100%;
                margin-right: 5px;
              }

              .specil_text {
                max-width: 81px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .price_con {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .price {
              font-size: 22px;
              color: #ff0100;
              margin: 0 0 10px;

              i {
                font-style: normal;
              }

              b {
                font-size: 12px;
                font-weight: 400;
                display: inline-block;
                color: #666;
                line-height: 0;
                border-top: 1px solid #666;
                margin-left: 10px;
                position: relative;
                top: -2px;

                i {
                  font-style: normal;
                }
              }
            }

            .lowest {
              width: 90px;
              height: 16px;
              background: #fff7e8;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 2px;
              margin-left: 15px;

              span {
                font-size: 12px;
                color: #ff5900;
                margin-left: 3px;
              }
            }
          }

          // 细分类目榜
          .rank-list {
            color: red;
            margin-top: 10px;
            cursor: pointer;

            .hot-ico {
              background: url("../../assets/detail/hot.png") center no-repeat;
              background-size: 100% 100%;
              width: 11px;
              height: 13px;
              display: inline-block;
              margin-right: 2px;
              margin-bottom: -2px;
            }
          }

          .buy-share {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .share-btn {
              display: flex;
              align-items: center;

              .buy {
                width: 120px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                background: #ff2e54;
                border-radius: 2px;
                display: inline-block;
                color: #fff;
                font-size: 14px;
                margin-right: 13px;
                cursor: pointer;

                &:hover {
                  background: #e31e43;
                  opacity: 0.9;
                }
              }

              .share_qrcode {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #fff;
                color: #ff5b00;
                border: 1px solid #ff5b00;
                cursor: pointer;

                &:hover {
                  background: #f9f9f9;
                }

                i {
                  background: url("../../assets/detail/qrcode_ico.png") center no-repeat;
                  background-size: 100% 100%;
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  margin-right: 5px;
                }

                .qrcode {
                  display: none;
                  line-height: 20px;
                  padding: 5px 0 10px;

                  span {
                    margin-top: 10px;
                  }

                  .loading {
                    width: 30px;
                    height: 30px;
                    animation: loading 1s linear infinite;
                  }

                  @keyframes loading {
                    100% {
                      transform: rotate(360deg);
                    }
                  }
                }

                &:hover .qrcode {
                  position: absolute;
                  top: 47px;
                  left: 0;
                  width: 120px;
                  background: #fff;
                  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 20%);
                  border-radius: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  padding-top: 10px;
                }
              }
            }
          }
        }

        .goods-desc {
          padding: 20px 0 0;

          .tit {
            font-weight: 700;
            color: #333;
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid #ececec;
          }

          .rec-text {
            color: #555;
            line-height: 24px;
            margin: 10px 0 15px;
          }

          .content {
            p {
              a {
                color: #42a0ee;
              }
            }
          }

          .feedback {
            font-size: 12px;
            margin-top: 30px;

            // span {
            //   background: #eee;
            //   color: #666;
            //   padding: 3px 10px;
            //   margin-right: 7px;
            //   cursor: pointer;
            //   &:hover {
            //     color: #ff2e54;
            //   }
            // }
            span {
              background: #eee;
              color: #666;
              padding: 3px 10px;
              margin-right: 7px;
              cursor: pointer;

              &:hover {
                color: #ff2e54;
              }
            }
          }
        }
      }

      .shop-msg {
        width: 100%;
        height: 145px;
        border: 1px solid #f1f1f1;
        background: #fff;

        .tit {
          font-size: 18px;
          height: 44px;
          line-height: 44px;
          background: #fff;
          border-bottom: 1px solid #f1f1f1;
          padding: 0 10px;
          box-sizing: border-box;

          span {
            border-left: 3px solid #ff2e54;
            padding: 2px 0 0 10px;
          }
        }

        .shop-block {
          height: 100px;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            width: 48%;
            display: flex;
            align-items: center;

            .shop-name {
              margin-left: 15px;
              display: initial;

              .iconfont {
                font-size: 12px;
                margin-top: 10px;
                color: #d81e06;
              }

              .icon-detail_tb {
                display: block;
                font-size: 12px;
                margin-top: 10px;
                color: #fc6421;
              }
            }
          }

          .shop-score {
            padding: 5px 0;
            box-sizing: border-box;
            display: flex;

            li {
              width: 100px;
              // float: left;
              font-size: 12px;
              color: #999;
              text-align: center;

              span {
                margin-top: 10px;
                display: block;
                font-size: 16px;
                color: #555;

                .icon {
                  position: relative;
                  top: -1px;
                  text-align: center;
                  font-family: "Microsoft YaHei";
                  border-radius: 2px;
                }

                .high {
                  width: 16px;
                  height: 16px;
                  font-style: initial;
                  line-height: 17px;
                  display: inline-block;
                  font-size: 12px;
                  color: #fff;
                  background: #e31436;
                }
              }
            }
          }
        }
      }

      .goods-detail {
        width: 100%;
        margin-top: 20px;
        border: 1px solid #f1f1f1;
        background: #fff;
        padding: 0 20px 20px;
        box-sizing: border-box;

        img {
          width: 100%;
          margin-top: -5px;
        }

        .tit {
          font-size: 18px;
          height: 44px;
          line-height: 44px;
          background: #fff;
          border-bottom: 1px solid #f1f1f1;
          padding: 0 10px;
          box-sizing: border-box;
          margin-bottom: 20px;
          width: 878px;
          margin-left: -20px;

          span {
            border-left: 3px solid #ff2e54;
            padding: 2px 0 0 10px;
          }
        }
      }
    }

    .right-container {
      margin-left: 20px;
    }
  }
}
</style>
