var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoad
    ? _c(
        "div",
        { staticClass: "taobao_container" },
        [
          _c("Loading", { attrs: { visible: _vm.loading } }),
          _c("div", { staticClass: "detail-wrapper" }, [
            _c(
              "div",
              { staticClass: "left-container", attrs: { "bi-route": "left" } },
              [
                _c("div", { staticClass: "detail-row" }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticClass: "img-block" }, [
                      _c("span", { staticClass: "img" }, [
                        _c("img", {
                          staticClass: "lg-img",
                          attrs: {
                            src: _vm.imgLargeUrl + "_640x640.jpg",
                            alt: ""
                          }
                        }),
                        _vm.images.length > 0
                          ? _c(
                              "ul",
                              { staticClass: "sm-img" },
                              _vm._l(_vm.imagess, function(item, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    on: {
                                      mouseenter: function($event) {
                                        return _vm.mousemove(item)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.openUrl,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          class:
                                            _vm.imgLargeUrl === item
                                              ? "img_hover"
                                              : "",
                                          attrs: {
                                            src: item + "_310x310.jpg",
                                            alt: ""
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "detail-col" }, [
                      _c(
                        "a",
                        { attrs: { href: _vm.openUrl, target: "_blank" } },
                        [
                          _c("span", { staticClass: "title" }, [
                            _vm.goodsData.haitao === 1
                              ? _c("span", { staticClass: "tmht" })
                              : _vm.goodsData.tchaoshi === 1
                              ? _c("span", { staticClass: "tmcs" })
                              : _vm.goodsData.shopType === 1
                              ? _c("span", { staticClass: "tm" })
                              : _vm._e(),
                            _c("span", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.goodsData.dtitle || _vm.goodsData.title
                                )
                              )
                            ])
                          ])
                        ]
                      ),
                      _vm.marketConfig
                        ? _c("div", { staticClass: "active-banners" }, [
                            _c(
                              "div",
                              {
                                staticClass: "act-bg",
                                style:
                                  "background-image:url(" +
                                  _vm.marketConfig.pc_detail_banner +
                                  ")"
                              },
                              [
                                _c("div", { staticClass: "act-text" }, [
                                  _vm.marketConfig.goods_type == 2 &&
                                  _vm.goodsData.previewStartTime
                                    ? _c(
                                        "span",
                                        { staticClass: "gf-ys gf-center" },
                                        [
                                          _c("back-end-time", {
                                            attrs: {
                                              time:
                                                _vm.goodsData.previewStartTime,
                                              label: "后可付定金"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.marketConfig.pc_detail_text &&
                                  _vm.marketConfig.goods_type == 1
                                    ? _c(
                                        "span",
                                        { staticClass: "gf-ys gf-center" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.marketConfig.pc_detail_text
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.marketConfig.goods_type == 2 &&
                                  _vm.goodsData.quanMLink &&
                                  _vm.goodsData.quanMLink != "0"
                                    ? _c("span", { staticClass: "gf-ys" }, [
                                        _vm._v(
                                          "定金" +
                                            _vm._s(_vm.goodsData.quanMLink) +
                                            "元抵" +
                                            _vm._s(
                                              _vm.AccAdd({
                                                num: [
                                                  _vm.goodsData.quanMLink,
                                                  _vm.goodsData.hzQuanOver || 0
                                                ]
                                              })
                                            ) +
                                            "元"
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.marketConfig.goods_type == 1
                                    ? _c(
                                        "span",
                                        { staticClass: "gf-ys" },
                                        [
                                          _c("back-date", {
                                            attrs: {
                                              ismarket: true,
                                              start:
                                                _vm.marketConfig.ac_start_time,
                                              end: _vm.marketConfig.ac_end_time,
                                              text: " "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              ]
                            )
                          ])
                        : _vm.goodsData.activityType === 3 &&
                          _vm.jhsEndTime > _vm.nowTime
                        ? _c("div", { staticClass: "jhs-banners" }, [
                            _c("div", { staticClass: "act-bg" }, [
                              _c(
                                "div",
                                { staticClass: "act-text" },
                                [
                                  _c("back-date", {
                                    attrs: {
                                      start: _vm.goodsData.activityStartTime,
                                      end: _vm.goodsData.activityEndTime
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "time-num" }, [
                        _vm.goodsData.createTime
                          ? _c("span", { staticClass: "time" }, [
                              _vm._v("上新时间：" + _vm._s(_vm.getTime))
                            ])
                          : _vm._e(),
                        _c("span", { staticClass: "num" }, [
                          _vm._v(
                            _vm._s(
                              _vm.marketConfig &&
                                _vm.marketConfig.goods_type == 2
                                ? "累计预定"
                                : "累计销量"
                            ) + "："
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("salesNum")(
                                  _vm.goodsData.monthSales,
                                  _vm._,
                                  1
                                )
                              )
                            )
                          ]),
                          _vm._v(" 件")
                        ])
                      ]),
                      _vm.goodsData.couponPrice && _vm.goodsData.couponPrice > 0
                        ? _c("div", { staticClass: "coupon" }, [
                            _vm._v(" 独家优惠："),
                            _c("span", { staticClass: "quan" }, [
                              _vm._v(_vm._s(_vm.goodsData.couponPrice) + "元券")
                            ])
                          ])
                        : _vm._e(),
                      _vm.goodsData.subdivisionRank > 0 &&
                      _vm.goodsData.couponPrice > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "rank-list",
                              on: {
                                click: function($event) {
                                  _vm.showTopList = true
                                }
                              }
                            },
                            [
                              _c("span", [
                                _c("i", { staticClass: "hot-ico" }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.goodsData.subdivisionName) +
                                    " | "
                                ),
                                _c("span", [
                                  _vm._v(
                                    "热销排行第" +
                                      _vm._s(_vm.goodsData.subdivisionRank) +
                                      "名>>"
                                  )
                                ])
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "goods-label",
                          style: {
                            borderTop: _vm.dom ? "1px solid #ececec" : "none"
                          }
                        },
                        [
                          _c("span", [_vm._v("商品标签：")]),
                          _vm.goodsData.specialText &&
                          _vm.goodsData.specialText[0]
                            ? _c("span", { staticClass: "label specil" }, [
                                _c("i", { staticClass: "specil-icon" }),
                                _c("span", { staticClass: "specil_text" }, [
                                  _vm._v(_vm._s(_vm.goodsData.specialText[0]))
                                ])
                              ])
                            : _vm._e(),
                          _vm.marketConfig && _vm.marketConfig.remark
                            ? _c("span", { staticClass: "label" }, [
                                _vm._v(_vm._s(_vm.marketConfig.remark))
                              ])
                            : _vm._e(),
                          _vm.goodsData.shopType
                            ? _c("span", { staticClass: "label" }, [
                                _vm._v("天猫")
                              ])
                            : _vm._e(),
                          _vm.goodsData.activityType === 3
                            ? _c("span", { staticClass: "label" }, [
                                _vm._v("聚划算")
                              ])
                            : _vm._e(),
                          _vm.goodsData.freeshipRemoteDistrict
                            ? _c("span", { staticClass: "label" }, [
                                _vm._v("包邮")
                              ])
                            : _vm._e(),
                          _vm.goodsData.discountFull
                            ? _c("span", { staticClass: "label" }, [
                                _vm._v(
                                  "每满" +
                                    _vm._s(_vm.goodsData.discountFull) +
                                    "减" +
                                    _vm._s(_vm.goodsData.discountCut) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price_con",
                          style: {
                            borderTop: _vm.dom ? "none" : "1px solid #ececec",
                            margin: _vm.dom ? 0 : "15px 0 5px",
                            padding: _vm.dom ? 0 : "15px 0 0"
                          }
                        },
                        [
                          _c("div", { staticClass: "price" }, [
                            _vm._v(" ￥ "),
                            _c("i", [
                              _vm._v(_vm._s(_vm.goodsData.actualPrice))
                            ]),
                            _vm.goodsData.couponPrice
                              ? _c("b", [
                                  _vm._v(" ￥"),
                                  _c("i", [
                                    _vm._v(_vm._s(_vm.goodsData.originalPrice))
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm.goodsData.lowest
                            ? _c("div", { staticClass: "lowest" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/detail/lowest.png"),
                                    alt: ""
                                  }
                                }),
                                _c("span", [_vm._v("30天最低价")])
                              ])
                            : _vm._e()
                        ]
                      ),
                      _c("div", { staticClass: "buy-share" }, [
                        _c("div", { staticClass: "share-btn" }, [
                          _vm.marketConfig && _vm.marketConfig.goods_type == 2
                            ? _c(
                                "a",
                                {
                                  staticClass: "buy",
                                  staticStyle: { background: "#a70fd1" },
                                  attrs: {
                                    "bi-route": "lingquan",
                                    href: _vm.openUrl,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("领券预定")]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass: "buy",
                                  attrs: { href: _vm.openUrl, target: "_blank" }
                                },
                                [_vm._v("领券购买")]
                              ),
                          _c(
                            "span",
                            {
                              staticClass: "buy share_qrcode",
                              attrs: { "bi-click": "saoma" },
                              on: { mouseenter: _vm.handleCode }
                            },
                            [
                              _c("i"),
                              _vm._v(" 扫码购买 "),
                              _c("div", { staticClass: "qrcode" }, [
                                _vm.isShow
                                  ? _c("img", {
                                      staticClass: "loading",
                                      attrs: {
                                        src: require("../../assets/detail/loading.png"),
                                        alt: ""
                                      }
                                    })
                                  : _vm._e(),
                                _c("span", { attrs: { id: "qrcode" } }),
                                _c("span", [_vm._v("手机淘宝扫码")])
                              ])
                            ]
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "goods-desc cf" }, [
                    _c("div", { staticClass: "tit" }, [_vm._v("推荐理由")]),
                    _c("div", { staticClass: "rec-text" }, [
                      _c("span", [_vm._v(_vm._s(_vm.goodsData.desc))])
                    ]),
                    _vm.goodsData.couponPrice
                      ? _c("div", { staticClass: "content" }, [
                          _c("p", [
                            _vm._v(
                              " 此款商品正在进行限时活动，原来天猫售价" +
                                _vm._s(_vm.goodsData.originalPrice) +
                                "元，现有" +
                                _vm._s(_vm.goodsData.couponPrice) +
                                "元优惠券，到手仅需" +
                                _vm._s(_vm.goodsData.actualPrice) +
                                "元，绝对超值，有需要可速度下单哦！ "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "feedback" },
                      [
                        _vm._v(" 我要举报： "),
                        _vm._l(_vm.reports, function(item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              on: {
                                click: function($event) {
                                  return _vm.toFeedback(item.err_type)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.content))]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _c("div", { staticClass: "shop-msg" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "shop-block" }, [
                    _c("div", [
                      _c("img", {
                        staticClass: "shop-img fl",
                        staticStyle: {
                          background: "rgb(245, 245, 245)",
                          display: "block"
                        },
                        attrs: {
                          src: _vm.goodsData.shopLogo
                            ? _vm.goodsData.shopLogo + "_310x310.jpg"
                            : require("../../assets/detail/default.png"),
                          alt: "",
                          width: "60",
                          height: "60"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "shop-name fl" },
                        [
                          _c("p", [_vm._v(_vm._s(_vm.goodsData.shopName))]),
                          _vm.goodsData.shopType
                            ? _c("p", {
                                staticClass: "iconfont icon-detail_tmall"
                              })
                            : _c("icon-font", {
                                staticClass: "iconfont icon-detail_tb",
                                attrs: { type: "icon-detail_taobao" }
                              })
                        ],
                        1
                      )
                    ]),
                    _c("ul", { staticClass: "shop-score fr" }, [
                      _c("li", [
                        _c("p", [_vm._v("宝贝描述")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.goodsData.dsrScore) + " ")
                        ])
                      ]),
                      _c("li", [
                        _c("p", [_vm._v("卖家服务")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.goodsData.serviceScore) + " ")
                        ])
                      ]),
                      _c("li", [
                        _c("p", [_vm._v("物流服务")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.goodsData.shipScore) + " ")
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm.detailPics && _vm.detailPics.length > 0
                  ? _c(
                      "div",
                      { staticClass: "goods-detail" },
                      [
                        _vm._m(1),
                        _vm._l(_vm.detailPics, function(item, index) {
                          return _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: item.img + "_640x640.jpg",
                                expression: "item.img + '_640x640.jpg'"
                              }
                            ],
                            key: index,
                            attrs: { src: item.img + "_640x640.jpg", alt: "" }
                          })
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticClass: "right-container" },
              [
                _c("maybe-like", {
                  attrs: {
                    items: _vm.likeItems,
                    fun: _vm.getDetailData,
                    funLike: _vm.getLikeData,
                    type: "taobao"
                  }
                }),
                _c("hot-sales", {
                  attrs: {
                    items: _vm.todayItmes,
                    fun: _vm.getDetailData,
                    funLike: _vm.getLikeData,
                    type: "taobao"
                  }
                })
              ],
              1
            )
          ]),
          _c("statement", { attrs: { items: _vm.id } }),
          _vm.showTopList
            ? _c("TopList", {
                attrs: {
                  items: _vm.hotSalesDataList,
                  title: _vm.goodsData.subdivisionName,
                  visible: _vm.showTopList
                },
                on: {
                  onClose: function($event) {
                    _vm.showTopList = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tit" }, [_c("span", [_vm._v("店铺信息")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tit" }, [_c("span", [_vm._v("商品详情")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }