var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length >= 3
    ? _c(
        "div",
        { staticClass: "rec-block fav", attrs: { "bi-route": "xihuan" } },
        [
          _c("div", { staticClass: "tit" }, [
            _c("span", [_vm._v("可能喜欢")]),
            _c(
              "ul",
              { staticClass: "pagination" },
              [
                _c(
                  "li",
                  { staticClass: "prev btn", on: { click: _vm.handlePrev } },
                  [
                    _c("icon-font", {
                      staticClass: "iconfont",
                      attrs: { type: "icon-zuojiantou" }
                    })
                  ],
                  1
                ),
                _vm._l(_vm.listLength, function(item, index) {
                  return _c("li", {
                    key: index,
                    staticClass: "pointer",
                    class: _vm.current === index ? "active" : "",
                    on: {
                      click: function($event) {
                        return _vm.tabChange(index)
                      }
                    }
                  })
                }),
                _c(
                  "li",
                  { staticClass: "next btn", on: { click: _vm.handleNext } },
                  [
                    _c("icon-font", {
                      staticClass: "iconfont",
                      attrs: { type: "icon-youjiantou" }
                    })
                  ],
                  1
                )
              ],
              2
            )
          ]),
          _c(
            "ul",
            _vm._l(_vm.tabList, function(ite, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "rec-list",
                  attrs: {
                    "bi-click": "list",
                    "data-index": index,
                    "data-gid": ite.id || ite.goodsId,
                    "data-pingtai": _vm.type
                  },
                  on: {
                    click: function($event) {
                      return _vm.toDel(ite.id || ite.goodsId)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "fl img-cent" }, [
                    _c("img", {
                      staticStyle: { background: "#ccc" },
                      attrs: {
                        src: ite.mainPic + "_310x310.jpg",
                        width: "90",
                        height: "90",
                        alt: ""
                      }
                    })
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      ite.haitao === 1
                        ? _c("i", { staticClass: "ico tmht" })
                        : ite.tchaoshi === 1
                        ? _c("i", { staticClass: "ico tmcs" })
                        : ite.shopType === 1
                        ? _c("i", { staticClass: "ico tm" })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(ite.dtitle || ite.title) + " ")
                    ]),
                    _c("section", { staticClass: "labels clearfix" }),
                    _c("span", { staticClass: "coupon" }, [
                      _c("i", [
                        _c("b", [_vm._v("¥")]),
                        _vm._v(_vm._s(ite.actualPrice))
                      ]),
                      _c("b", { staticClass: "old-pri" }, [
                        _vm._v("￥" + _vm._s(ite.originalPrice))
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }